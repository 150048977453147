<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

export default {
  name:'app',
}
</script>
<style lang="scss">
body,
html {
  overflow-x: hidden;
  font-family: "Roboto" !important;
  box-sizing: border-box;
  user-select: none;
  // font-size: 12px;
  ::-webkit-scrollbar {
  display: none;
  }
  ::-moz-scrollbar{
    display: none;
  }
  ::-o-scrollbar{
    display: none;
  }
}
#app {
   font-family:"Roboto" !important;
   padding: 0;
  margin: 0;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  width: 100vw;

 

}
 .img_box {
  
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100vw;
    max-height: 100vh;
    object-fit: contain;
  }
 .el-dialog {
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    margin-top: unset !important;
    margin-bottom: 0;
    width: unset;
  }
  ::v-deep .el-dialog__header {
    display: none;
  }
  ::v-deep .el-dialog {
    background: transparent;
  }
  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
  }
  ::v-deep .el-dialog__body {
    padding: 0;
    > img {
      object-fit: contain;
    }
    .contain_box .user_box img {
      object-fit: cover;
    }
  }
}

.app-center-modal {
    max-width: 500PX !important;
    height: 70vh !important;
    border-radius: 12PX !important;
    position: relative;
    overflow: hidden;
    width: auto;

    .el-dialog__headerbtn {
        z-index: 3;
        top: 0;
        margin-top: 1em;
        margin-right: 1em;
        i{
          font-size: 20PX;
        }
    }

    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}

@media only screen and (max-width: 500px) {
    .app-center-modal {
        max-width: 90% !important;
        .el-dialog__headerbtn {
        margin-top: 5em;
        margin-right: 5em;
    }
    }
}


</style>
